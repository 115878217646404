


























































































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { userServices } from "@/services/user.services";
import { ResponseListOfUsers, DataUser } from "@/models/interface/user.interface";
import { PropsModel, ResponsePagination } from "@/models/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { getauthorities } from "@/helpers/cookie";
import { checkPasswordStrength } from "@/validator/globalvalidator";
import router from "@/router";
import { mapGetters } from "vuex";
import { APP_ROLES } from "@/models/constant/role.constant";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
export default Vue.extend({
  name: "ListUsers",
  data() {
    return {
      authPrivilege: [] as string[],
      loadingTable: false as boolean,
      direction: "asc" as string,
      sort: "email:asc" as string,
      search: "" as string,
      limit: DEFAULT_PAGE_SIZE as number,
      page: 1 as number,
      totalData: 0 as number,
      payloadPagination: {},
      defaultPagination: false as boolean,
      modalCreateId: false as boolean,
      visibleModalChangePassword: false as boolean,
      userId: "" as string,
      columns: [
        {
          title: this.$t("lbl_email"),
          dataIndex: "email",
          key: "email",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_active"),
          dataIndex: "active",
          key: "active",
          scopedSlots: { customRender: "isActive" },
        },
        {
          title: this.$t("lbl_signin_username"),
          dataIndex: "username",
          key: "username",
          scopedSlots: { customRender: "isNull" },
        },
      ] as PropsModel[],
      dataSource: [] as DataUser[],
      isFormSubmitted2: false,
      formRulesChangePassword: {
        username: {
          label: this.$t("lbl_signin_username"),
          name: "username",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "username"
          ],
        },
        newPassword: {
          label: this.$t("lbl_new_password"),
          name: "newPassword",
          placeholder: this.$t("lbl_new_password"),
          decorator: [
            "newPassword",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
                {
                  validator: checkPasswordStrength,
                },
              ],
            },
          ],
        },
        confirmPassword: {
          label: this.$t("lbl_confirm_password"),
          name: "confirmPassword",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "confirmPassword",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
                {
                  validator: this.customValidator,
                }
              ],
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getUserRole: "authStore/GET_USER_ROLE"
    }),
    hasRoleAdmin(): boolean {
      return this.getUserRole.find(role => role.includes(APP_ROLES.ADMINISTRATOR, APP_ROLES.SUPER_ADMINISTRATOR));
    },
    formItemLayout2() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
  watch: {
    getUserRole(newVal): void {
      if (newVal && newVal.length) {
        this.setAuthorities();
        this.addActionChangePassword();
      }
    },
  },
  mounted() {
    this.getListOfUsers();
  },
  beforeCreate() {
    this.formChangePassword = this.$form.createForm(this, { name: "changePassword" });
  },
  created () {
    this.setAuthorities();
    this.addActionChangePassword();
  },
  methods: {
    setAuthorities(): void {
      if (!this.hasRoleAdmin) return;
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth?.name === "user") {
          this.authPrivilege = dataAuth.value;
          this.columns.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              // width: "120px",
              align: "center",
              scopedSlots: { customRender: "operation" },
              // fixed:"right",
              button: dataAuth.actions,
              responsiveMenu: [],
            },
          );
        }
      });
    },
    addActionChangePassword(): void {
      const roles = this.getUserRole.find(role => role.includes(APP_ROLES.ADMINISTRATOR, APP_ROLES.SUPER_ADMINISTRATOR));
      if (!roles) return;
      const act = {
        title: this.$t("lbl_profile_change_password"),
        icon: "edit",
        triggerMenu: this.handleChangePassword
      };
      const idx = this.columns.findIndex(col => col.dataIndex === "operation");
      if (idx !== -1) {
        this.columns[idx].responsiveMenu.push(act);
      }
    },
    handleCLickFLoating(){
      router.push("/user/create");
    },
    resetFilter():void{
      this.direction = "";
      this.search = "";
      this.getListOfUsers();
    },
    onSorterChange(response): void {
      this.direction = response.sort.order === "ascend" ? "asc" : "desc";
      this.sort = `${response.sort.field}:${this.direction}`;
      this.getListOfUsers();
    },
    handleChangePassword (record) {
      this.visibleModalChangePassword = true;
      this.userId = record.id;
      this.formChangePassword.setFieldsValue({
        username: record.username
      });
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfUsers();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfUsers();
    },
    reponseDeleteTable(response): void {
      userServices
        .deleteUser(response.data.id)
        .then((res: boolean) => {
          if (res) {
            this.$message.success(Messages.DELETE_SUCCESS);
            this.getListOfUsers();
          }
        })
        .catch(() => this.$message.error(Messages.DELETE_FAIL));
    },
    reponseEditTable(response): void {
      const id = response.data.id;
      this.$router.push({ name: "user-management.users.edit", params: {id} });
    },
    reponseSearchInput(response): void {
      this.page = 1;
      if (response) this.search = "email~*" + response + "*_OR_username~*" + response + "*";
      else this.search = "";
      this.getListOfUsers();
    },
    createUser(): void {
      this.modalCreateId = true;
    },
    handleCancel():void{
      this.modalCreateId = false;
      this.visibleModalChangePassword = false;
    },
    getListOfUsers ():void {
      let params = {
        limit: this.limit,
        page: this.page - 1,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sorts = this.sort;
      this.loadingTable = true;
      userServices
        .listOfUsers(params)
        .then((res: ResponseListOfUsers) => {
          this.totalData = res.totalElements;
          res.data.forEach((dataObject, index) => (dataObject.key = index));
          this.dataSource = res.data;

        })
        .finally(() => this.loadingTable = false);
      this.modalCreateId = false;
    },
    submitFormChangePassword(): void {
      this.formChangePassword.validateFields((err, values) => {
        if(err || this.isFormSubmitted2) return;
        this.isFormSubmitted2 = true;
        const payload = {
          userId: this.userId,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword
        };
        userServices
          .adminChangePassword(payload)
          .then((value) => {
            if (value) {
              this.visibleModalChangePassword = false;
              this.$message.success(Messages.CHANGE_PASSWORD_SUCCESS);
              this.formChangePassword.resetFields();
            }
          })
          .catch(() => this.isFormSubmitted2 = false)
          .finally(() => this.isFormSubmitted2 = false);
      });
    },
    customValidator (rule, value, callback) {
      if(value) {
        const newPassword = this.formChangePassword.getFieldValue("newPassword");
        if(newPassword === value) {
          callback();
        } else {
          callback("Password doesn't match");
        }
      } else {
        callback();
      }
    }
  }
});
